import { Switch } from 'antd';
import tw, { styled, css } from 'twin.macro';

export const StyledSwitch = styled(Switch)(() => [
  css`
    ${tw``};

    & .ant-switch-inner {
      background-color: #ffa9a9;
    }

    & .ant-switch-handle:before {
      background-color: #ff0000;
    }

    &.ant-switch-checked {
      & .ant-switch-inner {
        ${tw`bg-success-light`};
        background: lightgreen;
      }

      & .ant-switch-handle:before {
        background: darkgreen;
      }
    }
  `
]);
