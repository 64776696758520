// eslint-disable-next-line no-unused-vars
import { Layout } from 'antd';
import tw, { css, styled } from 'twin.macro';

export const Container = styled(Layout)(() => [
  css`
    ${tw`p-0 min-h-screen`};
  `
]);

export const Header = styled(Layout.Header)(() => [
  css`
    ${tw`p-0 bg-transparent top-0 sticky h-auto`};
    z-index: 1000;
  `
]);

export const Main = styled(Layout.Content)(({ theme }) => [
  css`
    min-height: calc(100% - ${theme.headerHeight}px);
  `
]);

export const Footer = styled(Layout.Footer)(() => [
  css`
    ${tw`p-0 bg-transparent`};
  `
]);
