import { i18n } from '@lingui/core';

import { messages as messagesDe } from 'services/i18n/locales/de/messages';
import { messages as messagesEn } from 'services/i18n/locales/en/messages';
import { messages as messagesFr } from 'services/i18n/locales/fr/messages';
import { messages as messagesIt } from 'services/i18n/locales/it/messages';
import { getSlugs } from 'utils';

export const intiI18n = () => {
  i18n.load('en', messagesEn);
  i18n.load('de', messagesDe);
  i18n.load('it', messagesIt);
  i18n.load('fr', messagesFr);

  i18n.loadLocaleData('en', { plurals: () => {} });
  i18n.loadLocaleData('de', { plurals: () => {} });
  i18n.loadLocaleData('it', { plurals: () => {} });
  i18n.loadLocaleData('fr', { plurals: () => {} });

  const language = getSlugs(window.location.pathname)?.[0];

  i18n.activate(language || 'de');
};
