import React from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { useIsRoleAllowed } from 'hooks';
import routes from 'services/router/routes';
import { getRoute, getSlugs } from 'utils';

import * as S from './MainNavigationSc';

const MainNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const base = getSlugs(pathname)?.[1];
  const language = getSlugs(pathname)?.[0];
  const isAdmin = useIsRoleAllowed('admin');

  return (
    <S.Container isAdmin={isAdmin}>
      <ul>
        {!isAdmin && (
          <li>
            <Link
              className={clsx([base === routes.info._segment && 'active'])}
              to={getRoute(routes.info._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('info-circle')} />
              <span className="label">
                <Trans>Info</Trans>
              </span>
            </Link>
          </li>
        )}
        {isAdmin ? (
          <li>
            <Link
              className={clsx([base === routes.admin.photos._segment && 'active'])}
              to={getRoute(routes.admin.photos._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('camera')} />
              <span className="label">
                <Trans>Photos</Trans>
              </span>
            </Link>
          </li>
        ) : (
          <li>
            <Link
              className={clsx([base === routes.photos._segment && 'active'])}
              to={getRoute(routes.photos._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('camera')} />
              <span className="label">
                <Trans>Photos</Trans>
              </span>
            </Link>
          </li>
        )}
        {isAdmin && (
          <li>
            <Link
              className={clsx([base === routes.admin.import._segment && 'active'])}
              to={getRoute(routes.admin.import._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('file-import')} />
              <span className="label">
                <Trans>Import</Trans>
              </span>
            </Link>
          </li>
        )}
        {/*  {!isAdmin && (
          <li>
            <Link
              className={clsx([base === routes.calendar._segment && 'active'])}
              to={getRoute(routes.calendar._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('calendar-alt')} />
              <span className="label">
                <Trans>Calendar</Trans>
              </span>
            </Link>
          </li>
        )} */}
        {!isAdmin && (
          <li>
            <Link
              className={clsx([base === routes.visit._segment && 'active'])}
              to={getRoute(routes.visit._path, { query: { language } })}
            >
              <FontAwesomeIcon icon={regular('location-dot')} />
              <span className="label">
                <Trans>Visit</Trans>
              </span>
            </Link>
          </li>
        )}
      </ul>
    </S.Container>
  );
};

export default MainNavigation;
