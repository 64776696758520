import tw, { styled, css } from 'twin.macro';

interface ContainerProps {
  isAdmin: boolean;
}
export const Container = styled.nav<ContainerProps>(({ isAdmin }) => [
  css`
    ${tw`h-full relative ml-32`};

    & ul {
      ${tw`flex items-center h-full`};

      & li {
        ${tw`h-full flex items-center`};

        & a {
          ${tw`py-22 md:mx-12 mr-30  font-500 opacity-60 border-b-4 border-transparent transition-all`};
          line-height: 1;
          border-bottom-width: 3px;

          & svg {
            ${tw`text-28 md:text-28`};
          }

          &.active {
            ${tw`opacity-100 border-primary-50`};
          }

          & .label {
            ${tw`hidden `};
          }
        }
      }
    }
  `,

  !isAdmin &&
    css`
      ${tw`ml-0`};

      & ul li a {
        ${tw`font-400 font-regular text-20 text-black pt-0 pb-4 opacity-100 border-b-2`}

        &.active {
          ${tw`opacity-100 border-black`};
        }
      }
    `
]);
