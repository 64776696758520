import tw, { styled, css } from 'twin.macro';

export const Container = styled.div(() => [
  css`
    ${tw`bg-white`};
  `
]);

export const Head = styled.div(() => [
  css`
    ${tw`flex justify-end px-12 py-8`};
  `
]);

export const Calendar = styled.div(() => [
  css`
    ${tw`grid gap-20 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-6 3xl:grid-cols-7 p-18`};
  `
]);

export const DayContainer = styled.ul(() => [
  css`
    ${tw`border border-gray-400 rounded-4 p-8 relative`}

    & > div {
      padding-top: 100%;

      & p {
        ${tw`absolute top-8 left-8 text-16 font-600 text-gray-500`}
      }

      & ul {
        ${tw`pr-5 grid gap-2 grid-cols-3 absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden overflow-y-auto`};

        & li {
          ${tw`h-auto`}
          height: 65%;
        }
      }
    }
  `
]);

export const Thumbnail = styled.div(() => [
  css`
    & img {
      ${tw`w-full h-auto`};
    }
  `
]);
