import React from 'react';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useGoto } from 'hooks';
import routes from 'services/router/routes';

import * as S from './ExceptionSc';

export interface ExceptionProps {
  title?: React.ReactNode | string;
  message?: React.ReactNode | string;
  icon?: IconDefinition;
  buttons?: React.ReactNode;
  code: number;
}

const Exception: React.FC<ExceptionProps> = ({ message, buttons, code }) => {
  const goto = useGoto();
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Content>
        <div className="left">
          <div>{code}</div>
        </div>
        <div className="right">
          <div>
            {message && <S.Message>{message}</S.Message>}
            <S.Buttons>
              {window.history.length > 1 && (
                <Button
                  type="primary"
                  onClick={() => navigate(-1)}
                  icon={<FontAwesomeIcon icon={regular('arrow-left')} />}
                >
                  <Trans>Back</Trans>
                </Button>
              )}
              <Button
                onClick={() => goto(routes.home._path)}
                icon={<FontAwesomeIcon icon={regular('home')} />}
              >
                <Trans>Home</Trans>
              </Button>
              {buttons}
            </S.Buttons>
          </div>
        </div>
      </S.Content>
    </S.Wrapper>
  );
};

export default Exception;
