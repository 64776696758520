import { Button } from 'antd';
import tw, { styled, css } from 'twin.macro';

interface Props {
  $isIconOnly?: boolean;
  $isAdmin: boolean;
}

export const Container = styled(Button)<Props>(({ $isIconOnly, $isAdmin }) => [
  css`
    ${tw`font-600 text-15 text-white px-14 font-sans min-w-60`};
    background: rgba(255, 255, 255, 0.1);

    & svg {
      ${tw`md:mr-8 text-28 md:text-20 -mr-12`};
    }

    &:hover {
      background: rgba(255, 255, 255, 0.16);
    }

    & .label {
      ${tw`hidden md:inline-block`};
    }
  `,

  $isIconOnly &&
    css`
      & svg {
        ${tw`mr-0`};
      }
    `,

  !$isAdmin &&
    css`
      ${tw`font-italic text-black font-400 text-20`};
    `
]);
