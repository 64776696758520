import { getRem } from '@arpia/utils';
import tw, { css, styled } from 'twin.macro';

export const Wrapper = styled.div(() => [
  css`
    ${tw`w-full h-full flex items-center justify-center`};
  `
]);

export const Content = styled.div(() => [
  css`
    ${tw`flex md:flex-row flex-col md:items-start items-center`};

    padding-bottom: 132px;

    & div.left {
      ${tw`flex items-center`};

      & div {
        ${tw`text-primary font-700`};
        font-size: ${getRem(64)};
      }
    }

    & div.right {
      ${tw`md:ml-24 md:pl-24 md:border-l-2 md:border-gray-500 items-center flex`};
      min-height: 10rem;

      & > div {
        transform: translateY(-2px);
      }
    }
  `
]);

export const Message = styled.div(() => [
  css`
    ${tw`text-16 font-600 text-gray-500 mb-12 text-center md:text-left`};

    & b {
      ${tw`text-primary font-600`};
    }
  `
]);

export const Buttons = styled.div(() => [
  css`
    ${tw`flex md:flex-row flex-col items-center mt-18 `};

    & .ant-btn {
      ${tw`mr-12 md:mb-0 mb-12 w-full md:w-auto`};

      & svg {
        ${tw`mr-6`};
      }
    }
  `
]);
