import React from 'react';

import { useMeasure } from 'react-use';

import * as S from './InfoSc';

const Info: React.FC = () => {
  const [ref, { height }] = useMeasure<any>();

  return (
    <S.InfoContainer>
      <h1 ref={ref}>Vault on a Cloud</h1>
      <div style={{ paddingTop: height + 54 }}>
        <p>
          Thomas Julier, Vault on a Cloud, 2023 Email-Schild, Solarpanel, Wildtierkamera mit
          SIM-Karte, Interface, Daten
          <br />
          <br />
        </p>
        <p>
          Die Beobachtung von Tieren in ihrem Habitat geschieht aus unterschiedlichen Motiven: als
          freizeitliches Vergnügen für Bildung und Erholung oder im Rahmen wissenschaftlicher
          Forschung. Diese Interessen stehen oft im Widerspruch zueinander, prägen den menschlichen
          Blick und nehmen dadurch Einfluss auf die Koexistenz von Mensch und Tier.
        </p>
        <p>
          Dieser Überlegung nachgehend hat Thomas Julier im Weiertal in einem vom Biber geprägten
          Biotop Wildtierkameras installiert. Der einst ausgerottete Nager schafft seit seiner
          Wiederansiedlung in der Schweiz artenreiche Lebensräume. Einen dieser Lebensräume im
          Weiertal beobachtet der Künstler mit Kameras. Sie registrieren Bewegungen, zeichnen sie
          mit Video auf und speichern die Aufzeichnungen in der Cloud. Diese sind über einen
          entsprechenden QR-Code einsehbar, den Thomas Julier im Garten auf einem Emaille-Schild
          platzierte. Das Schild erinnert an herkömmliche Wegweiser, Orts- und Strassenschilder und
          funktioniert als Eingang in die virtuelle Welt. Einmal gescannt, gelangen die
          Besucher*innen zu einem Login, das ihnen Zugang zu einer Datenbank verschafft. Dort sehen
          sie sowohl die Bilder der Wildtiere als auch die Aktivitäten der menschlichen
          Besucher*innen und können mit dem Bildmaterial interagieren.
        </p>
        <p>
          In Vault on a Cloud verknüpft Thomas Julier einen «wirklichen» Raum – den Garten im
          Weiertal – mit einem virtuellen – der Datensammlung und ihrem Interface – und schafft so
          einen hybriden Raum, dessen Dimensionen von der gleichzeitigen Präsenz von Mensch und Tier
          markiert werden. Die Datensammlung wächst im Verlauf der Ausstellung zu einem Volumen an,
          dessen plastische Qualitäten er als Skulptur versteht. So lädt uns Thomas Julier dazu ein,
          vertraute Vorstellungen zu revidieren und über zeitgemässe Massstäbe bei der Definition
          jener Lebensräume nachzudenken, die wir zusammen mit anderen Spezies gestalten.
        </p>
        <p>
          Thomas Julier (*1983 in Brig) lebt und arbeitet in Brig und Zürich. Durch die Medien
          Fotografie, Video, Sprache und Objekten setzt er sich mit Fragen der Wahrnehmung und der
          Konstruktion von Wirklichkeit auseinander. Seine Arbeiten zeichnen sich durch einen
          medienspezifischen Umgang mit Digitalität aus.
        </p>
        <div>
          <p>Kontakt: mail@thomasjulier.info</p>
          <p>
            Diese Software-Applikation wurde von Thomas Julier in Zusammenarbeit mit Thomas Sauter (
            <a href="https://arpia.ch" target="_blank" rel="noreferrer">
              https://arpia.ch
            </a>
            ) entwickelt.
          </p>
          <p>Copyright © 2023 Thomas Julier & Thomas Sauter, All Rights Reserved.</p>
        </div>
      </div>
    </S.InfoContainer>
  );
};

export default Info;
