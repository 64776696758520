import tw, { styled, css } from 'twin.macro';

import { appStyles } from 'styles/appStyles';

export const InfoContainer = styled.div(() => [
  css`
    ${tw`md:px-20 bg-white pb-24`};

    & h1 {
      ${tw`w-full text-center text-28 fixed top-80 left-0 right-0`};
      font-size: 124px;

      @media (max-width: ${appStyles.breakpoints.md - 1}px) {
        font-size: 48px;
      }
    }

    & > div {
      ${tw`mx-auto px-20`};
      max-width: 880px;
      padding-top: 200px;

      @media (max-width: ${appStyles.breakpoints.md - 1}px) {
        padding-top: 120px;
      }

      & > div {
        ${tw`pt-80`};
      }

      & p {
        ${tw`md:text-28 text-22`};
        margin-bottom: 1em;
      }

      & a {
        ${tw`underline`}
      }
    }
  `
]);
