import tw, { css, styled } from 'twin.macro';

export const Loader = styled.div(() => [
  css`
    ${tw`w-screen flex items-center justify-center absolute -mt-12 transition-all opacity-0`}
    top: 50%;

    animation-name: fadeIn;
    animation-delay: 600ms;
    animation-duration: 500ms;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
]);
