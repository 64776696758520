import { Dayjs } from 'dayjs';

import { apiClient } from 'services/api/clients';

import { Job } from '../jobs';

const BASE_PATH = 'admin/import';

/**
 * POST /api/admin/import/photos/{date}
 */
export const api_importPhotosByDate = async (date: Dayjs): Promise<Job> => {
  const { data } = await apiClient.post(`/${BASE_PATH}/photos/${date.format('YYYY-MM-DD')}`);

  return data;
};
