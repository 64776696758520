import { apiClient } from 'services/api/clients';

import { Myself } from './users.types';

const BASE_PATH = 'users';

/**
 * GET /api/users/myself
 */
export const api_getMyself = async (): Promise<Myself> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/myself`);

  return data;
};
