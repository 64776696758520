import React, { useCallback, useMemo, useState } from 'react';

import { ApiSearchQueryParams } from '@cheyes/shared';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { ApiPhoto, usePhotos } from 'services/api/rest/photos';

import * as S from './PhotosCalendarSc';

const COMPARE_DATE = 'YYYY-MM-DD';

const query: ApiSearchQueryParams = {
  pagination: {
    page: 1,
    limit: 1000
  },
  filters: [],
  sort: {
    field: 'email_date',
    direction: 'DESC'
  }
};

const PhotosCalendar: React.FC = () => {
  const [month, setMonth] = useState<Dayjs | null>(dayjs());
  const { data } = usePhotos(query);

  const getListData = useCallback(
    (date: Dayjs): ApiPhoto[] => {
      if (!data) return [];

      return data.items.filter(
        photo => dayjs(photo.date).format(COMPARE_DATE) === date.format(COMPARE_DATE)
      );
    },
    [data]
  );

  const dateCellRender = useCallback(
    (value: Dayjs) => {
      const listData = getListData(value);

      return (
        <S.DayContainer className="photos">
          <div>
            <p>{value.get('date')}</p>
            <ul>
              {listData.map(item => (
                <li key={item.id}>
                  <S.Thumbnail>
                    <img src={item.url} alt={item.fileName} />
                  </S.Thumbnail>
                </li>
              ))}
            </ul>
          </div>
        </S.DayContainer>
      );
    },
    [getListData]
  );

  const calendarDays = useMemo(() => {
    if (!month) return null;

    const daysInMonth = month.startOf('month').daysInMonth();

    const nodes: any = [];

    for (let i = 1; i <= daysInMonth; i += 1) {
      let dayNumber = '';
      if (i < 10) {
        dayNumber = `0${i}`;
      } else {
        dayNumber = i.toString();
      }

      const currentDate = dayjs(`2023-04-${dayNumber}`).startOf('day');

      const dateCell = dateCellRender(currentDate);

      nodes.push(dateCell);
    }

    return nodes;
  }, [dateCellRender, month]);

  return (
    <S.Container>
      <S.Head>
        <DatePicker value={month} picker="month" onChange={setMonth} />
      </S.Head>
      <S.Calendar>{calendarDays}</S.Calendar>
    </S.Container>
  );
};

export default PhotosCalendar;
