import { useMutation } from '@tanstack/react-query';
import type { Dayjs } from 'dayjs';

import { UseMutation } from 'services/api/types';

import { Job } from '../jobs';

import { api_importPhotosByDate } from './import.queries';

/**
 * MUTATIONS
 */
export const useImportPhotosByDate: UseMutation<Dayjs, Job> = options =>
  useMutation(params => api_importPhotosByDate(params), options);
