import { apiClient } from 'services/api/clients';

import { ApiLike } from './likes.types';

const BASE_PATH = 'likes';

/**
 * POST /api/likes/{photoId}
 */
export const api_likePhoto = async (photoId: number): Promise<ApiLike> => {
  const { data } = await apiClient.post(`/${BASE_PATH}/${photoId}`);

  return data;
};

/**
 * DELETE /api/likes/{photoId}
 */
export const api_unlikePhoto = async (photoId: number): Promise<void> => {
  const { data } = await apiClient.delete(`/${BASE_PATH}/${photoId}`);

  return data;
};
