import React, { PropsWithChildren } from 'react';

import { useMyself } from 'services/api/rest/users';

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  useMyself();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default UserProvider;
