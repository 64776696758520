import React, { useMemo, useState } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams } from '@cheyes/shared';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { useContainer } from 'components/containerProvider/useContainer';
import { DEFAULT_PHOTOS_QUERY } from 'config';
import { useMediaQueries } from 'hooks';
import { PhotoLazyLoadParams } from 'services/api/rest/photos';

import * as S from './PhotosGridSc';

interface SortType {
  label: string;
  field: string;
  isInverted?: boolean;
}

const Sort: React.FC = () => {
  const { isMobile } = useMediaQueries();

  const [isOpen, setOpen] = useState(false);
  const { content } = useContainer();
  const [, setId] = useQueryParam('id', NumberParam);

  const [queryString, setQueryString] = useQueryParam('q', StringParam);

  const query: PhotoLazyLoadParams = useMemo(() => {
    if (!queryString) return { ...DEFAULT_PHOTOS_QUERY };

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const sorts: SortType[] = useMemo(
    () => [
      {
        label: 'Date',
        field: 'date'
      },
      {
        label: 'Daytime',
        field: 'daytime'
      },
      {
        label: 'Location',
        field: 'location'
      },
      {
        label: 'Most visited',
        field: 'mostVisited'
      },
      {
        label: 'Last visited',
        field: 'lastVisited',
        isInverted: true
      },
      {
        label: 'Likes',
        field: 'likes'
      },
      {
        label: 'Most visited by me',
        field: 'mostVisitedByMe'
      },
      {
        label: 'Last visited by me',
        field: 'lastVisitedByMe',
        isInverted: true
      },
      {
        label: 'Liked by me',
        field: 'likesByMe'
      }
    ],
    []
  );

  const activeSort = useMemo(
    () => sorts.find(srt => srt.field === query.sort.field),
    [query.sort, sorts]
  );

  const handleToggleSort = () => {
    setOpen(false);

    if (!activeSort) return;

    const newQuery = cloneDeep(query);
    newQuery.sort.direction = query.sort.direction === 'ASC' ? 'DESC' : 'ASC';

    setId(undefined);
    setQueryString(objectToBase64(newQuery));
  };

  const handleSort = (srt: SortType) => {
    setOpen(false);

    if (activeSort?.field === srt.field) {
      handleToggleSort();

      return;
    }

    const newQuery = cloneDeep(query);
    newQuery.sort = {
      field: srt.field,
      direction: 'DESC'
    };

    setId(undefined);
    setQueryString(objectToBase64(newQuery));
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={op => setOpen(op)}
      placement="bottomLeft"
      trigger={isMobile ? 'click' : 'hover'}
      overlayInnerStyle={{ minWidth: 240 }}
      content={
        <S.SortList>
          {sorts.map(srt => (
            <li key={srt.field} className={clsx([activeSort?.field === srt.field && 'active'])}>
              <Button type="link" onClick={() => handleSort(srt)}>
                <span>{srt.label}</span>
                <FontAwesomeIcon
                  icon={
                    activeSort?.field === srt.field && query.sort.direction === 'ASC'
                      ? regular('arrow-up')
                      : regular('arrow-down')
                  }
                />
              </Button>
            </li>
          ))}
        </S.SortList>
      }
      getPopupContainer={() => content}
    >
      {activeSort && (
        <Button
          className="sort"
          icon={
            <FontAwesomeIcon
              icon={query.sort.direction === 'ASC' ? regular('arrow-up') : regular('arrow-down')}
            />
          }
          size="small"
          onClick={!isMobile ? handleToggleSort : undefined}
        >
          {activeSort.label}
        </Button>
      )}
    </Popover>
  );
};

export default Sort;
