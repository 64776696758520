import tw, { css, styled } from 'twin.macro';

import type { OverlayContainerType } from 'components/containerProvider/containerProvoder.types';

interface ContainerProps {
  type: OverlayContainerType;
}

export const Container = styled.div<ContainerProps>(({ type }) => [
  css`
    & .ant-popover {
      & .ant-popover-inner {
        ${tw`p-0`};
      }
    }

    /*  & .ant-popover-arrow {
      display: none;
    }

    & .ant-popover-inner {
     

      & .ant-menu {
        border-inline-end: none;
        margin-block: 0;

        & .ant-menu-item {
          ${tw`font-500`};

          &:hover {
            ${tw`bg-primary-50`};
          }
        }
      }
    } */
  `,

  type === 'content' && css``,

  type === 'notification' && css``,

  type === 'dialog' &&
    css`
      & .ant-modal-content {
        ${tw`p-0`};
      }

      & .video-dialog {
        & .ant-modal {
          ${tw`rounded-4 border-gray-700 border`};
        }
        & .ant-modal-mask {
          ${tw`bg-primary-1000 opacity-80`};
        }

        & .ant-modal-content {
          ${tw`bg-primary-1000 p-8 border-white border `};
        }

        & .ant-modal-body {
          ${tw`flex justify-center mt-24`};
        }

        & .anticon-close {
          ${tw`text-white opacity-20 absolute -top-10 -right-10 w-16 hover:opacity-100`};
        }

        & .ant-modal-footer {
          ${tw`hidden`}
        }
      }
    `
]);
