import { apiClient } from 'services/api/clients';

import { ApiVisit } from './visits.types';

const BASE_PATH = 'visits';

/**
 * POST /api/visits/{photoId}
 */
export const api_trackVisit = async (photoId: number): Promise<ApiVisit> => {
  const { data } = await apiClient.post(`/${BASE_PATH}/${photoId}`);

  return data;
};
