import { ApiSearchQueryParams } from '@cheyes/shared';

export const PHOTO_START_DATE = '2023-04-27';

export const IMAGE_RATIO = 1.3333333333333333;

export const PHOTOS_LAZY_PAGESIZE = 20;

export const DEFAULT_PHOTOS_QUERY: ApiSearchQueryParams = {
  pagination: {
    page: 1,
    limit: 20
  },
  filters: [],
  sort: {
    field: 'date',
    direction: 'DESC'
  }
};
