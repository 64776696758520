/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams, DATE_TIME_FORMAT } from '@cheyes/shared';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { findIndex } from 'lodash';
import { useInView } from 'react-intersection-observer';
import PulseLoader from 'react-spinners/PulseLoader';
import { useMeasure } from 'react-use';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import ImagePlaceholder from 'components/imagePlaceholder/ImagePlaceholder';
import { DEFAULT_PHOTOS_QUERY } from 'config';
import { useIsRoleAllowed } from 'hooks';
import { ApiPhoto, PhotoLazyLoadParams, usePhotosLazy } from 'services/api/rest/photos';
import { useMyself } from 'services/api/rest/users';
import { useTrackVisit } from 'services/api/rest/visits';
import { appStyles } from 'styles/appStyles';
import { ImageTransform } from 'types';

import DateFilter from './DateFilter';
import LocationFilter from './LocationFilter';
import MyselfFilter from './MyselfFilter';
import Overlay from './Overlay';
import * as S from './PhotosGridSc';
import Sort from './Sort';

const PhotosGrid: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { ref, inView, entry } = useInView({
    threshold: 1
  });
  const myselfQuery = useMyself({ enabled: false });
  const isAdmin = useIsRoleAllowed('admin');
  const [queryString, setQueryString] = useQueryParam('q', StringParam);

  const query: PhotoLazyLoadParams = useMemo(() => {
    if (!queryString) return { ...DEFAULT_PHOTOS_QUERY };

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    remove,
    fetchNextPage,
    refetch
  } = usePhotosLazy(query);
  const [id, setId] = useQueryParam('id', NumberParam);

  const trackVisitMutation = useTrackVisit({
    onSuccess: () => {
      // queryClient.refetchQueries(['users', 'myself']);
    }
  });
  const { current: trackVisitMutationRef } = useRef(trackVisitMutation);

  const dataFlat = useMemo(() => {
    const all: ApiPhoto[] = [];

    data?.pages.forEach(p => {
      p.items.forEach(item => all.push(item));
    });

    return all;
  }, [data?.pages]);

  const currentPhoto = dataFlat.find(video => video.id === id);
  const [headerRef, { height }] = useMeasure<HTMLDivElement>();

  const currentIndex = useMemo(() => {
    if (!currentPhoto || !data || dataFlat.length === 0) return null;

    return findIndex(dataFlat, { id: currentPhoto.id });
  }, [currentPhoto, data, dataFlat]);

  const next = useMemo(() => {
    if (currentIndex === null || !data || currentIndex === dataFlat.length - 1) return null;

    return dataFlat[currentIndex + 1];
  }, [currentIndex, data, dataFlat]);

  const prev = useMemo(() => {
    if (!currentIndex || !data) return null;

    return dataFlat[currentIndex - 1];
  }, [currentIndex, data, dataFlat]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!currentPhoto || isAdmin) return;

    trackVisitMutationRef.mutate(currentPhoto.id);
  }, [currentPhoto, isAdmin, trackVisitMutationRef]);

  useEffect(() => {
    if (inView && data && !isLoading && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, entry, data, isLoading, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage]);

  const handleReload = useCallback(() => {
    setId(undefined);
    window.scrollTo({ top: 0 });
    remove();
    refetch();
  }, [refetch, remove, setId]);

  const isLikedByMe = useCallback(
    (photoId: number) => {
      if (!myselfQuery.data || !myselfQuery.data.likes) return false;

      return myselfQuery.data.likes.includes(photoId);
    },
    [myselfQuery.data]
  );

  const getImgUrl = (img: ApiPhoto) => {
    const tr: ImageTransform = {
      resize: {
        width: 400,
        fit: 'contain'
      }
    };

    const trStr = objectToBase64(tr);

    return `${window._env_.IMG_TRANSFORM_API_URL}/transforms/bucket/${
      window._env_.IMG_TRANSFORM_API_BUCKET
    }/${encodeURIComponent(img.key)}/${trStr}?apikey=${window._env_.IMG_TRANSFORM_API_KEY}`;
  };

  useEffect(() => {
    handleReload();
  }, [handleReload, query]);

  useEffect(() => {
    if (!queryString) {
      setQueryString(objectToBase64(DEFAULT_PHOTOS_QUERY));
    }
  }, [queryString, setQueryString]);

  return (
    <S.Container>
      <S.Header ref={headerRef}>
        <Button
          size="small"
          icon={<FontAwesomeIcon icon={solid('undo')} />}
          onClick={() => setQueryString(objectToBase64(DEFAULT_PHOTOS_QUERY))}
        />
        {/*  <Button
          size="small"
          icon={<FontAwesomeIcon icon={solid('sync')} />}
          onClick={() => handleReload()}
        /> */}
        <Sort />
        <DateFilter />
        <LocationFilter />
        <MyselfFilter />
        {/* <MyselfFilter /> */}
        {/* <Select className="filter" size="small" allowClear placeholder="Select daytime">
          <Select.Option>Morning</Select.Option>
          <Select.Option>Noon</Select.Option>
          <Select.Option>Afternoon</Select.Option>
          <Select.Option>Evening</Select.Option>
          <Select.Option>Night</Select.Option>
        </Select> */}

        {/* <VisitsFilter />
        <LikesFilter /> */}
      </S.Header>
      {isLoading ? (
        <S.Loader>
          <PulseLoader size={12} color={appStyles.colors['primary-100']} />
        </S.Loader>
      ) : (
        <S.Grid>
          {dataFlat.map(item => (
            <S.GridItem
              isActive
              key={item.id}
              className="group"
              role="button"
              onClick={() => {
                setId(item.id);
              }}
            >
              {item.url ? (
                <div className="img-container">
                  <img src={getImgUrl(item)} alt={item.fileName} />
                </div>
              ) : (
                <ImagePlaceholder isGrid />
              )}
              <div className="info">
                <div>
                  <p>{dayjs(item.date).format(DATE_TIME_FORMAT)}</p>
                </div>
                <div>
                  <p>
                    <FontAwesomeIcon icon={regular('eye')} />
                    {item.visitsCount}
                  </p>
                  <p>
                    {isLikedByMe(item.id) ? (
                      <FontAwesomeIcon icon={solid('heart')} /* color="red"  */ />
                    ) : (
                      <FontAwesomeIcon icon={regular('heart')} />
                    )}
                    {item.likesCount}
                  </p>
                </div>
              </div>
            </S.GridItem>
          ))}
        </S.Grid>
      )}

      <div ref={ref} style={{ height: 40 }} />
      {currentPhoto && (
        <Overlay
          currentPhoto={currentPhoto}
          next={next}
          prev={prev}
          headerHeight={height}
          isLikedByMe={isLikedByMe(currentPhoto.id)}
        />
      )}
    </S.Container>
  );
};

export default PhotosGrid;
