import tw, { css, styled } from 'twin.macro';

import { IMAGE_RATIO } from 'config';
import { appStyles } from 'styles/appStyles';

export const Container = styled.div(() => [
  css`
    ${tw`px-20 bg-white pb-24 pt-2`};
  `
]);

export const Header = styled.section(() => [
  css`
    ${tw`flex md:pt-8 md:pb-20 pb-0 sticky top-64 flex-wrap bg-white`};
    z-index: 1000;

    & .filter {
      ${tw`mr-12 lg:min-w-180 mb-12 md:mb-0`};
    }

    & .ant-btn {
      ${tw`mr-12 mb-12 md:mb-0`};
    }

    & .sort {
      ${tw`mr-12`};

      & svg {
        ${tw`mr-8`};
      }
    }

    & .toggle-btn {
      & svg {
        ${tw`opacity-40`}
      }

      &.active {
        ${tw`border-gray-500`}

        & svg {
          ${tw`opacity-100`}
        }
      }
    }
  `
]);

export const Grid = styled.div(() => [
  css`
    ${tw`grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 relative gap-24`};

    & > div {
      ${tw`w-full`};
    }
  `
]);

interface GridItemProps {
  isActive: boolean;
}
export const GridItem = styled.div<GridItemProps>(({ isActive }) => [
  css`
    ${tw`transition-all duration-300 relative`};

    & .info {
      ${tw`text-black flex justify-between text-15 text-center leading-18 pt-6 opacity-90 transition-all font-regular`};
      line-height: 1em;

      & > div {
        ${tw`flex gap-10 whitespace-nowrap`}

        & svg {
          ${tw`text-15 mr-2`}
        }
      }
    }

    & .img-container {
      ${tw`relative bg-gray-200`};
      padding-top: ${(1 / IMAGE_RATIO) * 100}%;

      & img {
        ${tw`absolute top-0 left-0 w-full h-full`};
        object-fit: contain;
      }
    }
  `,

  !isActive &&
    css`
      ${tw`opacity-80`}

      img {
        ${tw`opacity-40`}
      }
    `
]);

interface OverlayProps {
  headerHeight: number;
}
export const Overlay = styled.article<OverlayProps>(({ theme, headerHeight }) => [
  css`
    ${tw`fixed top-0 left-0 bottom-0 right-0 bg-white  pb-20 `};
    top: ${theme.headerHeight + headerHeight + 8}px;

    & .actions {
      ${tw`absolute bottom-12 right-20 left-20 lg:left-auto flex flex-row items-end gap-12`}
      z-index: 9000;

      & .ant-btn {
        ${tw`bg-white opacity-80 border-none mb-8`}

        & svg {
          ${tw`text-24`}
        }

        &.close-btn {
          ${tw`hidden lg:inline-block`}
        }
      }

      &.mobile-close {
        ${tw`top-0 right-0 left-auto bottom-auto`}

        & .ant-btn.close-btn {
          ${tw`lg:hidden inline-block`}
        }
      }

      & .visits {
        ${tw`text-18 bg-white rounded-full w-40 h-40 overflow-hidden flex items-center justify-center opacity-80 mb-8`}

        & svg {
          ${tw`mr-4 text-13`}
        }
      }
    }

    @media (max-width: ${appStyles.breakpoints.lg - 1}px) {
      top: 0;
      bottom: 0;
      background: white;
      z-index: 99999;
      padding-bottom: 0;

      & > div {
        background: white;
        height: 100vh;
        max-height: 100vh !important;
      }
    }

    & > div {
      ${tw`h-full w-full relative overflow-hidden flex items-center justify-center`}
      max-height: calc(100vh - ${theme.headerHeight + headerHeight}px);

      & img {
        ${tw`mx-auto w-screen cursor-pointer`};
        object-fit: contain;
        object-position: center;
        max-height: calc(100vh - ${theme.headerHeight}px);
      }
    }
  `
]);

export const SortList = styled.ul(() => [
  css`
    ${tw`min-w-180`};

    & > li {
      ${tw`w-full m-0 px-12`};

      & .ant-btn {
        ${tw`p-0 text-black text-16 w-full flex items-center justify-between hover:text-black`};
      }

      &:hover,
      &.active {
        ${tw`bg-gray-100`};

        & .ant-btn {
        }
      }
    }
  `
]);

export const Loader = styled.div(() => [
  css`
    ${tw`w-screen flex items-center justify-center absolute -mt-12 transition-all opacity-0`}
    top: 50%;

    animation-name: fadeIn;
    animation-delay: 600ms;
    animation-duration: 500ms;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
]);
