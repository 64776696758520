import { useContext } from 'react';

import { ThemeContext } from 'styled-components';

export const useMediaQueriesConfig = () => {
  const { breakpoints } = useContext(ThemeContext);

  return {
    desktop: `(min-width: ${breakpoints.md}px)`,
    tablet: `(min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.md - 1}px)`,
    phone: `(max-width: ${breakpoints.xs - 1}px)`,
    print: `print`
  };
};
