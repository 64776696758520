import { useMutation } from '@tanstack/react-query';

import { UseMutation } from 'services/api/types';

import { api_likePhoto, api_unlikePhoto } from './likes.queries';
import { ApiLike } from './likes.types';

export const useLikePhoto: UseMutation<number, ApiLike> = options =>
  useMutation(photoId => api_likePhoto(photoId), options);

export const useUnlikePhoto: UseMutation<number, void> = options =>
  useMutation(photoId => api_unlikePhoto(photoId), options);
