import React, { memo, useEffect, useMemo, useState } from 'react';

import { isEqual, keys } from 'lodash';
import type { RouteObject } from 'react-router';
import { Navigate, useRoutes } from 'react-router';

import Layout from 'components/layout';
import NotFound from 'components/notFound';
import { useDefaultLocale } from 'hooks';
import { RouteConfig, pathParams } from 'services/router/routes';
import { RoutePath } from 'services/router/routes.types';
import { getRoute } from 'utils';

interface RouterProps {
  routes: RouteConfig;
}

const RouterRenderer: React.FC<RouterProps> = ({ routes }) => {
  const defaultLanguage = useDefaultLocale();

  const rtsConfig = useMemo(() => {
    const walk = (path: RoutePath): RouteObject => {
      const routeConfig: RouteObject = {
        path: path._segment,
        element: path._element
      };

      const children: RouteObject[] = [];

      if (path._index) {
        children.push({
          index: true,
          element: path._index
        });
      }

      keys(path).forEach(key => {
        if (key !== '_path' && key !== '_element' && key !== '_segment' && key !== '_index') {
          const res = walk((path as any)[key]);
          children?.push(res);
        }
      });

      routeConfig.children = children.length ? children : undefined;

      return routeConfig;
    };

    const children: RouteObject[] = [];

    keys(routes).forEach(key => {
      const res = walk((routes as any)[key]);
      children.push(res);
    });

    const config: RouteObject[] = [
      {
        element: <Layout />,
        children: [
          {
            path: '/',
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    replace
                    to={getRoute(routes.info._path, {
                      query: {
                        language: defaultLanguage
                      }
                    })}
                  />
                )
              },
              {
                path: pathParams.language,
                children: [
                  ...children,
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              }
            ]
          }
        ]
      }
    ];

    return config;
  }, [defaultLanguage, routes]);

  const [prevRtsConfig, setPrevRtsConfig] = useState(rtsConfig);

  useEffect(() => {
    if (!isEqual(rtsConfig, prevRtsConfig)) {
      setPrevRtsConfig(rtsConfig);
    }
  }, [rtsConfig, prevRtsConfig]);

  return useRoutes(prevRtsConfig);
};

const Router = memo(RouterRenderer);

export default Router;
