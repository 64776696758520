import React from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { NumberParam, useQueryParam } from 'use-query-params';

import Image from 'components/image/Image';
import { useLikePhoto, useUnlikePhoto } from 'services/api/rest/likes';
import { ApiPhoto } from 'services/api/rest/photos';

import * as S from './PhotosGridSc';

interface Props {
  currentPhoto: ApiPhoto;
  next?: ApiPhoto | null;
  prev?: ApiPhoto | null;
  headerHeight: number;
  isLikedByMe: boolean;
}

const Overlay: React.FC<Props> = ({ currentPhoto, next, prev, headerHeight, isLikedByMe }) => {
  const [, setId] = useQueryParam('id', NumberParam);
  const queryClient = useQueryClient();

  const likeMutation = useLikePhoto({
    onSuccess: () => {
      queryClient.refetchQueries(['users', 'myself']);
    }
  });

  const unlikeMutation = useUnlikePhoto({
    onSuccess: () => {
      queryClient.refetchQueries(['users', 'myself']);
    }
  });

  const handleLike = (photoId: number) => {
    if (isLikedByMe) {
      unlikeMutation.mutate(photoId);

      return;
    }

    likeMutation.mutate(photoId);
  };

  if (!currentPhoto) return null;

  return (
    <S.Overlay headerHeight={headerHeight + 20}>
      <div>
        <div className="actions mobile-close">
          <Button
            className="close-btn"
            shape="circle"
            icon={<FontAwesomeIcon icon={regular('close')} />}
            onClick={() => setId(undefined)}
          />
        </div>
        <div className="actions">
          <Button
            className="close-btn"
            shape="circle"
            icon={<FontAwesomeIcon icon={regular('close')} />}
            onClick={() => setId(undefined)}
          />
          {prev && (
            <Button
              className="prev-btn"
              shape="circle"
              icon={<FontAwesomeIcon icon={regular('angle-left')} />}
              onClick={() => setId(prev.id)}
            />
          )}
          {next && (
            <Button
              className="next-btn"
              shape="circle"
              icon={<FontAwesomeIcon icon={regular('angle-right')} />}
              onClick={() => setId(next.id)}
            />
          )}
          <Button
            className="like-btn"
            shape="circle"
            icon={
              isLikedByMe ? (
                <FontAwesomeIcon icon={solid('heart')} color="red" />
              ) : (
                <FontAwesomeIcon icon={regular('heart')} />
              )
            }
            onClick={() => handleLike(currentPhoto.id)}
          />
          <div className="visits">
            <FontAwesomeIcon icon={regular('eye')} />
            {currentPhoto.visitsCount}
          </div>
          <div className="visits">
            <FontAwesomeIcon icon={regular('heart')} />
            {currentPhoto.likesCount}
          </div>
        </div>

        <Image s3Key={currentPhoto.key} close={() => setId(undefined)} />
      </div>
    </S.Overlay>
  );
};

export default Overlay;
