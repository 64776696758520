import React, { useMemo } from 'react';

import { useMeasure } from 'react-use';

import { useMediaQueries } from 'hooks';

import * as S from './VisitSc';

const Visit: React.FC = () => {
  const [ref, { height }] = useMeasure<any>();
  const { isMobile } = useMediaQueries();

  const iframeH = useMemo(() => {
    if (isMobile) {
      return height + 280;
    }

    return height + 300;
  }, [height, isMobile]);

  return (
    <S.Container>
      <h1 ref={ref}>COMMON GROUND</h1>
      <div style={{ paddingTop: height + 54 }}>
        <p>
          8. Biennale im Weiertal
          <br />
          kuratiert von Sabine Rusterholz Petko
          <br />
          21. Mai - 10. September 2023
        </p>
      </div>
      <iframe
        style={{ height: `calc(100vh - ${iframeH}px)` }}
        title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.4957464267136!2d8.665485799999999!3d47.4997359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a9eb9e5676ea7%3A0xe80694d911032e86!2sKulturort%20Weiertal%20mit%20Biennale!5e0!3m2!1sen!2sch!4v1682857977238!5m2!1sen!2sch"
        width="600"
        height="450"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </S.Container>
  );
};

export default Visit;
