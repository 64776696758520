import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import {
  ApiAdminPhoto,
  useActivatePhotoById,
  useDeactivatePhotoById
} from 'services/api/rest/photos';

import * as S from './ToogleActiveActionSc';

interface ToogleActiveActionProps {
  data: ApiAdminPhoto;
}

const ToogleActiveAction: React.FC<ToogleActiveActionProps> = ({ data }) => {
  const queryClient = useQueryClient();

  const handleUpdate = () => {
    queryClient.refetchQueries(['admin', 'photos']);
  };

  const deactivateMutation = useDeactivatePhotoById({
    onSuccess: () => {
      handleUpdate();
    }
  });

  const activateMutation = useActivatePhotoById({
    onSuccess: () => {
      handleUpdate();
    }
  });

  const handleToggleActive = () => {
    if (data.active) {
      deactivateMutation.mutate(data.id);

      return;
    }

    activateMutation.mutate(data.id);
  };

  return (
    <S.StyledSwitch
      className="toggle-active"
      disabled={deactivateMutation.isLoading || activateMutation.isLoading}
      size="small"
      checked={data.active}
      onChange={handleToggleActive}
    />
  );
};

export default ToogleActiveAction;
