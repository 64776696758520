import type { PropsWithChildren } from 'react';
import React from 'react';

import { Outlet } from 'react-router';

import Header from 'components/header';

import * as S from './LayoutSc';

const Layout: React.FC<PropsWithChildren> = () => (
  <S.Container>
    <S.Header>
      <Header />
    </S.Header>
    <S.Main>
      <Outlet />
    </S.Main>
    {/* <S.Footer>footer</S.Footer> */}
  </S.Container>
);

export default Layout;
