import React, { PropsWithChildren, useMemo } from 'react';

import { ConfigProvider } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import itIT from 'antd/es/locale/it_IT';
import { ThemeProvider } from 'styled-components';

import { useIsRoleAllowed, useLanguage } from 'hooks';
import { appStyles } from 'styles/appStyles';

const StylesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const language = useLanguage();
  const isAdmin = useIsRoleAllowed('admin');

  const antLocale = useMemo(() => {
    switch (language) {
      case 'de':
        return deDE;
      case 'fr':
        return frFR;
      case 'it':
        return itIT;
      default:
        return enUS;
    }
  }, [language]);

  return (
    <ConfigProvider
      locale={antLocale}
      theme={{
        token: {
          controlHeight: 40,
          controlHeightLG: 44,
          controlHeightSM: 34,
          fontSize: 15,
          fontFamily:
            appStyles.fonts.regular && !isAdmin
              ? appStyles.fonts.regular.join(',')
              : appStyles.fonts.sans.join(','),
          colorPrimary: appStyles.colors['primary-500'],
          colorWarning: appStyles.colors.warning,
          colorWarningBg: appStyles.colors['warning-bg'],
          colorError: appStyles.colors.error,
          colorErrorBg: appStyles.colors['error-bg'],
          colorSuccess: appStyles.colors.success,
          colorLink: appStyles.colors.primary,
          colorLinkHover: appStyles.colors.primary,
          colorLinkActive: appStyles.colors.primary,
          colorBgBase: '#FFFFFF'
        },
        components: {
          Layout: {
            colorBgBase: '#FFFFFF',
            colorBgContainer: '#FFFFFF'
          },
          Menu: {
            margin: 0,
            paddingContentVertical: 0,
            borderRadius: 0,
            borderRadiusOuter: 0,
            itemMarginInline: 0
          },
          Popover: {
            sizePopupArrow: 0
          },
          Pagination: {
            controlHeightSM: 24
          }
        }
      }}
    >
      <ThemeProvider theme={{ ...appStyles, headerHeight: 64 }}>{children}</ThemeProvider>
    </ConfigProvider>
  );
};

export default StylesProvider;
