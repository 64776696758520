import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import AuthProvider from 'components/authProvider';
import ContainerProvider from 'components/containerProvider';
import LanguageProvider from 'components/languageProvider';
import Router from 'components/router';
import StylesProvider from 'components/stylesProvider/StylesProvider';
import UserProvider from 'components/userProvider/UserProvider';
import { useLanguage } from 'hooks';
import { queryClient } from 'services/api/clients';
import authClient from 'services/auth';
import { intiI18n } from 'services/i18n';
import routes from 'services/router/routes';
import { useAuthStore } from 'services/store/auth';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

const App = () => {
  const language = useLanguage();
  const setToken = useAuthStore(st => st.setToken);

  const handleTokens = (tokens: any) => {
    setToken(tokens?.token as string);
  };

  const handleEvent = (event: unknown, error: unknown) => {
    if (window._env_.ENVIRONMENT !== 'prod') {
      switch (event) {
        case 'onReady':
          // eslint-disable-next-line no-console
          console.warn('READY', event, error);
          break;
        case 'onAuthLogout':
          // eslint-disable-next-line no-console
          console.warn('LOGOUT', event, error);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekYear);
    dayjs.extend(utc);

    dayjs.locale(i18n._locale);
  }, [language]);

  useEffect(() => {
    intiI18n();
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        checkLoginIframe: true,
        checkLoginIframeInterval: 10,
        onLoad: 'login-required',
        useNonce: true
      }}
      onEvent={handleEvent}
      onTokens={handleTokens}
    >
      <React.StrictMode>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            /* options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }} */
          >
            <QueryClientProvider client={queryClient}>
              {/* @ts-ignore */}
              <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
                <LanguageProvider>
                  <ContainerProvider>
                    <AuthProvider>
                      <UserProvider>
                        <StylesProvider>
                          <Router routes={routes} />
                        </StylesProvider>
                      </UserProvider>
                    </AuthProvider>
                  </ContainerProvider>
                </LanguageProvider>
              </I18nProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
};

export default App;
