import tw, { styled, css } from 'twin.macro';

import { appStyles } from 'styles/appStyles';

export const Container = styled.div(() => [
  css`
    ${tw`md:px-20 bg-white`};

    & h1 {
      ${tw`w-full text-center text-26 fixed top-80 left-0 right-0`};
      font-size: 124px;

      @media (max-width: ${appStyles.breakpoints.md - 1}px) {
        font-size: 48px;
      }
    }

    & > div {
      ${tw`mx-auto px-20`};
      max-width: 880px;
      padding-top: 200px;

      @media (max-width: ${appStyles.breakpoints.md - 1}px) {
        font-size: 100px;
      }

      & > div {
        ${tw`pt-80`};
      }

      & p {
        ${tw`text-28`};
        margin-bottom: 1em;
      }
    }

    & iframe {
      ${tw`w-screen lg:-ml-20 lg:mt-80`};
      height: calc(100vh - 446px);
    }
  `
]);
