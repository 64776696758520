import React, { useMemo } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams } from '@cheyes/shared';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { DEFAULT_PHOTOS_QUERY } from 'config';
import { PhotoLazyLoadParams } from 'services/api/rest/photos';

const MyselfFilter: React.FC = () => {
  const [queryString, setQueryString] = useQueryParam('q', StringParam);

  const query: PhotoLazyLoadParams = useMemo(() => {
    if (!queryString) return { ...DEFAULT_PHOTOS_QUERY };

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const handleFilter = (myFilter?: string) => {
    const newQuery = cloneDeep(query);
    newQuery.filters = newQuery.filters.filter(f => f.field !== 'myself');

    if (myFilter) {
      newQuery.filters.push({ field: 'myself', values: [myFilter] });
    }

    setQueryString(objectToBase64(newQuery));
  };

  const isLikesActive = useMemo(
    () => query.filters.find(flt => flt.field === 'myself')?.values.includes('likes'),
    [query.filters]
  );

  const isVisitsActive = useMemo(
    () => query.filters.find(flt => flt.field === 'myself')?.values.includes('visits'),
    [query.filters]
  );

  return (
    <>
      <Button
        size="small"
        className={clsx(['toggle-btn', isLikesActive && 'active'])}
        icon={<FontAwesomeIcon icon={!isLikesActive ? light('heart') : solid('heart')} />}
        onClick={() => (isLikesActive ? handleFilter() : handleFilter('likes'))}
      />

      <Button
        size="small"
        className={clsx(['toggle-btn', isVisitsActive && 'active'])}
        icon={<FontAwesomeIcon icon={!isVisitsActive ? light('eye') : solid('eye')} />}
        onClick={() => (isVisitsActive ? handleFilter() : handleFilter('visits'))}
      />
    </>
  );
};

export default MyselfFilter;
