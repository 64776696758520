import type { ApiSearchQueryParams, Video } from '@cheyes/shared';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { PHOTOS_LAZY_PAGESIZE } from 'config';
import { UseMutation, UseQueryWithParams } from 'services/api/types';

import {
  api_activateVideo,
  api_adminGetPhotoById,
  api_adminGetPhotos,
  api_deactivateVideo,
  api_getPhotos,
  api_getPhotosById,
  api_rateVideoById
} from './photos.queries';
import {
  AdminPhotosListResponse,
  ApiAdminPhoto,
  PhotoLazyLoadParams,
  PhotosListResponse,
  RateVideoParams
} from './photos.types';

/**
 * QUERIES
 */
export const usePhotos: UseQueryWithParams<ApiSearchQueryParams, PhotosListResponse> = (
  queryParams,
  options
) => useQuery(['photos'], ({ signal }) => api_getPhotos(queryParams, signal), options as any);

export const usePhotosLazy = (
  params: PhotoLazyLoadParams,
  options: UseInfiniteQueryOptions<
    PhotosListResponse,
    AxiosError,
    PhotosListResponse,
    PhotosListResponse,
    [string, string, PhotoLazyLoadParams]
  > = { enabled: false }
) =>
  useInfiniteQuery(
    ['photos', 'searchlazy', params],
    ({ pageParam = 1, signal }) =>
      api_getPhotos(
        { ...params, pagination: { page: pageParam, limit: PHOTOS_LAZY_PAGESIZE } },
        signal
      ),
    {
      ...options,
      // eslint-disable-next-line no-unused-vars
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.meta.totalPages >= lastPage.meta.currentPage + 1) {
          return lastPage.meta.currentPage + 1;
        }

        return undefined;
      },
      // eslint-disable-next-line no-unused-vars
      getPreviousPageParam: (firstPage, allPages) => {
        if (firstPage.meta.totalPages - 1 >= 1) {
          return firstPage.meta.currentPage - 1;
        }

        return undefined;
      }
    }
  );

export const usePhotoById: UseQueryWithParams<number, Video> = (id, options) =>
  useQuery(['videos', 'byId', id], ({ signal }) => api_getPhotosById(id, signal), options as any);

// ////////////////////////////////////////////////////////////////////////////////////////////////////
// ADMIN
export const useAdminPhotos: UseQueryWithParams<ApiSearchQueryParams, AdminPhotosListResponse> = (
  queryParams,
  options
) =>
  useQuery(
    ['admin', 'photos'],
    ({ signal }) => api_adminGetPhotos(queryParams, signal),
    options as any
  );

export const useAdminPhotoById: UseQueryWithParams<number, ApiAdminPhoto> = (id, options) =>
  useQuery(
    ['admin', 'photos', 'byId', id],
    ({ signal }) => api_adminGetPhotoById(id, signal),
    options as any
  );

/**
 * MUTATIONS
 */

// ////////////////////////////////////////////////////////////////////////////////////////////////////

export const useActivatePhotoById: UseMutation<number, Video> = options =>
  useMutation(id => api_activateVideo(id), options);

export const useDeactivatePhotoById: UseMutation<number, Video> = options =>
  useMutation(id => api_deactivateVideo(id), options);

export const useRatePhotoById: UseMutation<RateVideoParams, Video> = options =>
  useMutation(params => api_rateVideoById(params), options);
