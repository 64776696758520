import React, { useMemo, useState } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams } from '@cheyes/shared';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { useContainer } from 'components/containerProvider/useContainer';
import { DEFAULT_PHOTOS_QUERY, PHOTO_START_DATE } from 'config';
import { PhotoLazyLoadParams } from 'services/api/rest/photos';

const DateFilter: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { content } = useContainer();

  const [queryString, setQueryString] = useQueryParam('q', StringParam);

  const query: PhotoLazyLoadParams = useMemo(() => {
    if (!queryString) return { ...DEFAULT_PHOTOS_QUERY };

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const value = useMemo(() => {
    const dateFilter = query.filters.find(fl => fl.field === 'date');
    if (!dateFilter) return undefined;

    return dayjs(dateFilter.values[0]);
  }, [query.filters]);

  const handleFilter = (date: Dayjs | null) => {
    const newQuery = cloneDeep(query);
    newQuery.filters = newQuery.filters.filter(f => f.field !== 'date');
    if (date) {
      newQuery.filters.push({
        field: 'date',
        values: [date.startOf('day').toISOString(), date.endOf('day').toISOString()]
      });
    }

    setOpen(false);
    setQueryString(objectToBase64(newQuery));
  };

  return (
    <DatePicker
      onOpenChange={o => setOpen(o)}
      open={isOpen}
      getPopupContainer={() => content}
      size="small"
      className="filter"
      value={value}
      onChange={handleFilter}
      disabledDate={d => {
        if (d > dayjs()) {
          return true;
        }

        if (d < dayjs(PHOTO_START_DATE)) {
          return true;
        }

        return false;
      }}
    />
  );
};

export default DateFilter;
