import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonProps } from 'antd';
import { omit } from 'lodash';

import { useIsRoleAllowed } from 'hooks';

import * as S from './HeaderButtonSc';

interface Props extends Omit<ButtonProps, 'icon'> {
  icon: IconDefinition;
  isIconOnly?: boolean;
}

const HeaderButton: React.FC<Props> = props => {
  const { icon, isIconOnly } = props;
  const isAdmin = useIsRoleAllowed('admin');

  return (
    <S.Container
      $isAdmin={isAdmin}
      type="ghost"
      {...omit(props, ['icon', 'isIconOnly'])}
      icon={<FontAwesomeIcon icon={icon} />}
      $isIconOnly={isIconOnly}
    />
  );
};

export default HeaderButton;
