import type { ApiSearchQueryParams, Video } from '@cheyes/shared';
import { stringify } from 'qs';

import { apiClient } from 'services/api/clients';

import {
  AdminPhotosListResponse,
  ApiAdminPhoto,
  PhotosListResponse,
  RateVideoParams
} from './photos.types';

const BASE_PATH = 'photos';
const BASE_PATH_ADMIN = 'admin/photos';

/**
 * GET /api/photos
 */
export const api_getPhotos = async (
  queryParams: ApiSearchQueryParams,
  signal?: AbortSignal
): Promise<PhotosListResponse> => {
  const query = stringify(queryParams, { encodeValuesOnly: true });
  const { data } = await apiClient.get(`/${BASE_PATH}`, { signal, params: { query } });

  return data;
};

/**
 * GET /api/photos/{id}
 */
export const api_getPhotosById = async (
  id: number,
  signal?: AbortSignal
): Promise<ApiAdminPhoto> => {
  const { data } = await apiClient.get(`/${BASE_PATH}/${id}`, { signal });

  return data;
};

// ////////////////////////////////////////////////////////////////////////////////////////////////////
// ADMIN

/**
 * GET /api/admin/photos
 */
export const api_adminGetPhotos = async (
  queryParams: ApiSearchQueryParams,
  signal?: AbortSignal
): Promise<AdminPhotosListResponse> => {
  const query = stringify(queryParams, { encodeValuesOnly: true });
  const { data } = await apiClient.get(`/${BASE_PATH_ADMIN}`, { signal, params: { query } });

  return data;
};

/**
 * GET /api/admin/photos/{id}
 */
export const api_adminGetPhotoById = async (
  id: number,
  signal?: AbortSignal
): Promise<ApiAdminPhoto> => {
  const { data } = await apiClient.get(`/${BASE_PATH_ADMIN}/${id}`, { signal });

  return data;
};

/**
 * PATCH /api/videos/activate/{id}
 */
export const api_activateVideo = async (id: number): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH_ADMIN}/activate/${id}`);

  return data;
};

/**
 * PATCH /api/videos/deactivate/{id}
 */
export const api_deactivateVideo = async (id: number): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH_ADMIN}/deactivate/${id}`);

  return data;
};

// ////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * PATCH /api/videos/rating/{id}/{rating}
 */
export const api_rateVideoById = async (params: RateVideoParams): Promise<Video> => {
  const { data } = await apiClient.patch(`/${BASE_PATH}/rating/${params.id}/${params.rating}`);

  return data;
};
