import React, { useMemo, useState } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams } from '@cheyes/shared';
import { Select } from 'antd';
import { cloneDeep } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { useContainer } from 'components/containerProvider/useContainer';
import { DEFAULT_PHOTOS_QUERY } from 'config';
import { PhotoLazyLoadParams } from 'services/api/rest/photos';

const LocationFilter: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { content } = useContainer();

  const [queryString, setQueryString] = useQueryParam('q', StringParam);

  const query: PhotoLazyLoadParams = useMemo(() => {
    if (!queryString) return { ...DEFAULT_PHOTOS_QUERY };

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const value = useMemo(
    () => query.filters.find(fl => fl.field === 'location')?.values[0],
    [query.filters]
  );

  const handleFilter = (location?: string) => {
    const newQuery = cloneDeep(query);
    newQuery.filters = newQuery.filters.filter(f => f.field !== 'location');

    if (location) {
      newQuery.filters.push({ field: 'location', values: [location] });
    }

    setOpen(false);
    setQueryString(objectToBase64(newQuery));
  };

  return (
    <Select
      getPopupContainer={() => content}
      onDropdownVisibleChange={o => setOpen(o)}
      open={isOpen}
      className="filter"
      size="small"
      allowClear
      placeholder="Select location"
      onChange={handleFilter}
      value={value}
    >
      <Select.Option value="WEIERTAL_1">Weiertal 1</Select.Option>
      <Select.Option value="WEIERTAL_2">Weiertal 2</Select.Option>
    </Select>
  );
};

export default LocationFilter;
