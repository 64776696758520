import { useMediaQuery } from 'react-responsive';

import { useMediaQueriesConfig } from './useMediaQueriesConfig';

export const useMediaQueries = () => {
  const config = useMediaQueriesConfig();
  const isDesktop = useMediaQuery({ query: config.desktop });
  const isTablet = useMediaQuery({ query: config.tablet });
  const isPhone = useMediaQuery({ query: config.phone });
  const isMobile = !isDesktop;
  const isPrint = useMediaQuery({ query: config.print });

  return {
    isDesktop,
    isTablet,
    isPhone,
    isMobile,
    isPrint
  };
};
