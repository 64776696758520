import React from 'react';

import { Trans } from '@lingui/macro';

import Exception from 'components/exception';

const NotFoundPage: React.FC = () => (
  <Exception code={404} message={<Trans>The requested page was not found.</Trans>} />
);

export default NotFoundPage;
