/**
 * Do not edit this file. its generically produced form js modules
 */
import type { AppStylesConfig } from 'types';

export const appStyles: AppStylesConfig = {
  htmlFontSize: 10,
  defaultFontSize: 14,
  fonts: {
    sans: ['"Inter"', 'sans-serif'],
    mono: ['"BradfordMonoLL-Regular"', 'monospace'],
    regular: ['"BradfordLL-Regular"', 'serif'],
    italic: ['"BradfordLL-Italic"', 'serif'],
    bold: ['"BradfordLL-Bold"', 'serif']
  },
  breakpoints: {
    xs: 480,
    sm: 576,
    md: 1000,
    lg: 1200,
    xl: 1400,
    xxl: 1600
  },
  colors: {
    'primary': '#eff4f8',
    'primary-50': '#cfdfe9',
    'primary-100': '#afc9db',
    'primary-200': '#ac9cee',
    'primary-300': '#8fb4cd',
    'primary-400': '#6f9fbe',
    'primary-500': '#4f89b0',
    'primary-600': '#417090',
    'primary-700': '#325770',
    'primary-800': '#243e50',
    'primary-900': '#152530',
    'primary-1000': '#070c10',

    'success-dark': '#007936',
    'success': '#009744',
    'success-light': '#00bd55',
    'success-bg': '#e5f4ec',

    'error-dark': '#a62219',
    'error': '#d02b20',
    'error-light': '#d9554c',
    'error-bg': '#fff5f4',

    'warning-dark': '#d9b53a',
    'warning': '#EFBD11',
    'warning-light': '#f7df8d',
    'warning-bg': '#fdf8e7',

    'mark': '#fff79f'
  }
};
