import React, { PropsWithChildren, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router';

import { useGoto, useIsRoleAllowed } from 'hooks';
import routes from 'services/router/routes';

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();
  const isAdmin = useIsRoleAllowed('admin');
  const goto = useGoto();

  useEffect(() => {
    if (isAdmin) return;

    if (
      pathname === '/' ||
      pathname === '/en' ||
      pathname === '/en/home' ||
      pathname.search('admin') !== -1
    ) {
      goto(routes.photos._path);
    }
  }, [goto, isAdmin, pathname]);

  if (!keycloak.authenticated) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthProvider;
