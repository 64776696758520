/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';

import { objectToBase64 } from '@arpia/utils';
import PulseLoader from 'react-spinners/PulseLoader';

import { appStyles } from 'styles/appStyles';
import { ImageTransform } from 'types';

import * as S from './ImageSc';

interface Props extends Partial<HTMLImageElement> {
  s3Key: string;
  widths?: number[];
  transforms?: ImageTransform;
  close: () => void;
}

const DEFAULT_WIDTHS = [640, 750, 828, 1080, 1200, 1920 /* , 2048, 3840 */];

const Image: React.FC<Props> = ({
  alt,
  loading,
  className,
  decoding,
  widths,
  s3Key,
  transforms,
  close
}) => {
  const [isLoading, setLoading] = useState(true);

  const srcSet = useMemo(() => {
    const ww = widths || DEFAULT_WIDTHS;
    const sets: string[] = [];

    ww.forEach(w => {
      const tr: ImageTransform = {
        ...transforms,
        resize: {
          width: w,
          fit: 'contain'
        }
      };

      const trStr = objectToBase64(tr);

      sets.push(
        `${window._env_.IMG_TRANSFORM_API_URL}/transforms/bucket/${
          window._env_.IMG_TRANSFORM_API_BUCKET
        }/${encodeURIComponent(s3Key)}/${trStr}?apikey=${window._env_.IMG_TRANSFORM_API_KEY} ${w}w`
      );
    });

    return sets.join(', ');
  }, [s3Key, transforms, widths]);

  useEffect(() => {
    setLoading(true);
  }, [s3Key]);

  return (
    <>
      {isLoading && (
        <S.Loader>
          <PulseLoader size={12} color={appStyles.colors['primary-100']} />
        </S.Loader>
      )}
      <img
        onClick={close}
        key={s3Key}
        onLoad={() => setLoading(false)}
        className={className}
        loading={loading || 'lazy'}
        decoding={decoding || 'async'}
        srcSet={srcSet}
        alt={alt}
        sizes="100vw"
      />
    </>
  );
};

export default Image;
