import React from 'react';

import { Navigate } from 'react-router';

import Imports from 'components/imports';
import Info from 'components/info/Info';
import PageWrapper from 'components/pageWrapper';
import PhotosCalendar from 'components/photosCalendar/PhotosCalendar';
import PhotosGrid from 'components/photosGrid/PhotosGrid';
import PhotosTable from 'components/photosTable/PhotosTable';
import Visit from 'components/visit/Visit';

export const pathParams = {
  language: ':language',
  id: ':id'
};

const basePath = `${pathParams.language}`;

const getRoutes = () => ({
  home: {
    _segment: 'home',
    _path: `/${basePath}/home`,
    _element: <Navigate to="/en/photos" replace />
  },

  calendar: {
    _segment: 'calendar',
    _path: `/${basePath}/calendar`,
    _element: <PageWrapper component={<PhotosCalendar />} />
  },

  info: {
    _segment: 'info',
    _path: `/${basePath}/info`,
    _element: <PageWrapper component={<Info />} />
  },

  visit: {
    _segment: 'visit',
    _path: `/${basePath}/visit`,
    _element: <PageWrapper component={<Visit />} />
  },

  photos: {
    _segment: 'photos',
    _path: `/${basePath}/photos`,
    _element: <PageWrapper component={<PhotosGrid />} />
  },

  admin: {
    _segment: 'admin',
    _path: `/${basePath}/admin`,

    photos: {
      _segment: 'photos',
      _path: `/${basePath}/admin/photos`,
      _element: <PageWrapper component={<PhotosTable />} />
    },

    import: {
      _segment: 'import',
      _path: `/${basePath}/admin/import`,
      _element: <PageWrapper component={<Imports />} />
    }
  }
});

const routes = getRoutes();

export type RouteConfig = ReturnType<typeof getRoutes>;

export default routes as RouteConfig;
