import React from 'react';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { useLocation } from 'react-router';

import HeaderButton from 'components/headerButton';
import MainNavigation from 'components/mainNavigation/MainNavigation';
import { useIsRoleAllowed } from 'hooks';
import { useMyself } from 'services/api/rest/users';
import routes from 'services/router/routes';
import { getRoute, getSlugs } from 'utils';

import * as S from './HeaderSc';

const Header: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();
  const base = getSlugs(pathname)?.[1];
  const language = getSlugs(pathname)?.[0];
  const isAdmin = useIsRoleAllowed('admin');
  const { data } = useMyself({ enabled: false });

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <S.Container isAdmin={isAdmin}>
      <S.Left>
        {isAdmin && (
          <S.Logo
            isAdmin={isAdmin}
            className={clsx([base === 'home' && 'active'])}
            to={getRoute(routes.home._path, { query: { language } })}
          >
            <h2>Chameleon Eyes Weiertal</h2>
          </S.Logo>
        )}
        <MainNavigation />
      </S.Left>

      <S.Right>
        {data && (
          <>
            <span className="username">{data.username}</span>
            {/* {data.visits && (
              <HeaderButton
                className="visits"
                icon={regular('eye')}
                // eslint-disable-next-line no-alert
                onClick={() => alert('go to my visits...')}
              >
                <span className="number">{data.visits.length}</span>
              </HeaderButton>
            )} */}
          </>
        )}
        <HeaderButton icon={regular('sign-out')} onClick={handleLogout} />
      </S.Right>
    </S.Container>
  );
};

export default Header;
