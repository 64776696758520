import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

interface Props {
  isAdmin?: boolean;
}
export const Container = styled.div<Props>(({ isAdmin, theme }) => [
  css`
    ${tw`bg-primary-1000 flex text-white px-16 justify-between items-center`};
    height: ${theme.headerHeight}px;
  `,

  !isAdmin &&
    css`
      ${tw`bg-white text-black font-bold`};
    `
]);

export const Logo = styled(Link)<Props>(({ isAdmin }) => [
  css`
    ${tw`inline-block relative h-full flex items-center justify-center opacity-80`};

    & h2 {
      ${tw`text-32 font-bold leading-24 text-blue-50`};
    }

    &:hover,
    &.active {
      ${tw`opacity-100`};

      & h2 {
        ${tw`text-white`};
      }
    }
  `,

  !isAdmin &&
    css`
      & h2 {
        ${tw`font-regular text-black`};
      }

      &:hover,
      &.active {
        & h2 {
          ${tw`text-black`};
        }
      }
    `
]);

export const Left = styled.div(() => [
  css`
    ${tw`flex items-start h-full flex-grow`};
  `
]);

export const Right = styled.div(() => [
  css`
    ${tw`ml-40 flex items-start justify-end`};

    & .username {
      ${tw`flex items-center font-regular text-22 h-40 mt-2 hidden md:flex md:mr-16 -mt-4`}
      line-height: 1;
    }

    & .ant-btn {
      ${tw`pr-0 pt-0`}

      & svg {
        ${tw`mr-0 text-28`}
      }
    }
  `
]);
