import React, { useEffect, useMemo } from 'react';

import { base64toObject, objectToBase64 } from '@arpia/utils';
import { ApiSearchQueryParams, DATE_TIME_FORMAT, Video, defaultVideosQuery } from '@cheyes/shared';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { findIndex } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import ImagePlaceholder from 'components/imagePlaceholder/ImagePlaceholder';
import ToogleActiveAction from 'components/toogleActiveAction';
import { ApiAdminPhoto, useAdminPhotos } from 'services/api/rest/photos';

import * as S from './PhotosTableSc';

const DEFAULT_MODE = 'grid';

const PhotosTable: React.FC = () => {
  const [queryString, setQueryString] = useQueryParam('q', StringParam);
  const [mode, setMode] = useQueryParam<'list' | 'grid'>('mode');
  const [id, setId] = useQueryParam('id', NumberParam);
  const [row, setRow] = useQueryParam('row', NumberParam);
  const query: ApiSearchQueryParams = useMemo(() => {
    if (!queryString) return defaultVideosQuery;

    return base64toObject(queryString) as ApiSearchQueryParams;
  }, [queryString]);

  const { data, isLoading, refetch } = useAdminPhotos(query);
  const currentPhoto = data?.items.find(video => video.id === id);

  const currentIndex = useMemo(() => {
    if (!currentPhoto || !data || !data?.items) return null;

    return findIndex(data.items, { id: currentPhoto.id });
  }, [currentPhoto, data]);

  const next = useMemo(() => {
    if (currentIndex === null || !data || currentIndex === data.items.length - 1) return null;

    return data.items[currentIndex + 1];
  }, [currentIndex, data]);

  const prev = useMemo(() => {
    if (!currentIndex || !data) return null;

    return data.items[currentIndex - 1];
  }, [currentIndex, data]);

  const columns: ColumnsType<ApiAdminPhoto> = useMemo(
    () => [
      {
        title: <FontAwesomeIcon icon={regular('image')} />,
        dataIndex: 'photo_filePath',
        render: (thumbUrl: string, record) =>
          thumbUrl ? (
            <S.Thumbnail
              src={thumbUrl}
              alt={record.name}
              /* style={{ opacity: record.active ? 1 : 0.3 }} */
            />
          ) : (
            <ImagePlaceholder />
          ),
        width: 120
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true
      },
      {
        title: 'Email date',
        dataIndex: 'email_date',
        render: (date: string) => dayjs(date).format(DATE_TIME_FORMAT),
        sorter: true
      },

      {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        sorter: true,
        render: (date: string) => dayjs(date).format(DATE_TIME_FORMAT)
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        sorter: true
      }
    ],
    []
  );

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  useEffect(() => {
    if (!queryString) {
      setQueryString(objectToBase64(defaultVideosQuery));
    }
  }, [queryString, setQueryString]);

  useEffect(() => {
    if (!mode) {
      setMode(DEFAULT_MODE);
    }
  }, [setMode, mode]);

  useEffect(() => {
    if (!id) return;

    setRow(id);
  }, [id, setRow]);

  return (
    <>
      <S.Container>
        <S.Header>
          <S.SearchInput>
            <Input.Search size="small" allowClear />
          </S.SearchInput>
          <div className="right">
            <S.Paginator>
              <Pagination
                total={data?.meta.totalItems}
                current={query.pagination?.page}
                pageSize={query.pagination?.limit}
                size="small"
                responsive
                onChange={(page, pageSize) => {
                  const newQuery: ApiSearchQueryParams = {
                    ...query,
                    pagination: {
                      page: page || 1,
                      limit: pageSize || 10
                    }
                  };

                  window.scrollTo({ top: 0 });

                  setQueryString(objectToBase64(newQuery));
                }}
              />
            </S.Paginator>
            <Button
              className={clsx([mode === 'list' && 'active'])}
              size="small"
              icon={<FontAwesomeIcon icon={solid('list')} type="ghost" />}
              onClick={() => setMode('list')}
            />
            <Button
              className={clsx([mode === 'grid' && 'active'])}
              size="small"
              icon={<FontAwesomeIcon icon={solid('grid')} type="ghost" />}
              onClick={() => setMode('grid')}
            />
          </div>
        </S.Header>

        {mode === 'list' && (
          <S.TableWrapper>
            <Table
              scroll={{ scrollToFirstRowOnChange: true }}
              size="small"
              loading={isLoading}
              dataSource={data?.items}
              columns={columns}
              rowKey="id"
              rowSelection={{
                type: 'radio',
                selectedRowKeys: row ? [row] : [],
                onSelect: record => setId(record.id)
              }}
              onRow={record => ({
                onClick: e => {
                  const tg = e.target as HTMLElement;
                  if (tg.classList.contains('controls') || tg.closest('.controls') !== null) {
                    return;
                  }
                  setId(record.id);
                }
              })}
              pagination={
                false
                /* {
                total: data?.meta.totalItems,
                current: query.pagination?.page,
                pageSize: query.pagination?.limit,
                size: 'small',
                responsive: true
              } */
              }
              tableLayout="auto"
              onChange={(pagination, filter, _sorter) => {
                const sorter = _sorter as SorterResult<Video>;

                const { sort } = query;
                if (sorter.column?.dataIndex && sorter.order) {
                  sort.field = sorter.column?.dataIndex as string;
                  sort.direction = sorter.order === 'ascend' ? 'ASC' : 'DESC';
                }

                const newQuery: ApiSearchQueryParams = {
                  ...query,
                  sort,
                  pagination: {
                    page: pagination.current || 1,
                    limit: pagination.pageSize || 10
                  }
                };

                window.scrollTo({ top: 0 });

                setQueryString(objectToBase64(newQuery));
              }}
            />
          </S.TableWrapper>
        )}

        {mode === 'grid' && (
          <S.Grid>
            {data?.items.map(item => (
              <S.GridItem
                isActive={item.active}
                key={item.id}
                className="group"
                role="button"
                onClick={(e: any) => {
                  const tg = e.target as HTMLElement;
                  if (tg.classList.contains('controls') || tg.closest('.controls') !== null) {
                    return;
                  }

                  setId(item.id);
                }}
              >
                {item.filePath ? (
                  <div className="img-container">
                    <img src={item.filePath} alt={item.name} />
                  </div>
                ) : (
                  <ImagePlaceholder isGrid />
                )}
                <div className="info">
                  <p>{item.name}</p>
                  <p>{dayjs(item.emailDate).format(DATE_TIME_FORMAT)}</p>
                </div>
                <div className="controls">
                  <ToogleActiveAction data={item} />
                </div>
              </S.GridItem>
            ))}
          </S.Grid>
        )}
      </S.Container>

      {currentPhoto && (
        <Modal
          centered
          width={1000}
          open={currentPhoto !== undefined}
          cancelText="close"
          onCancel={() => setId(undefined)}
          title={currentPhoto?.name}
          //   getContainer={() => dialog}
          rootClassName="photo-dialog"
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <S.PhotoDetail />
          <img src={currentPhoto.filePath} alt={currentPhoto.name} />
          <S.PhotoNav>
            {prev && (
              <Button
                size="small"
                icon={<FontAwesomeIcon icon={solid('angle-left')} />}
                onClick={() => setId(prev.id)}
              />
            )}
            <div>
              <ToogleActiveAction data={currentPhoto} />
            </div>
            {next && (
              <Button
                size="small"
                icon={<FontAwesomeIcon icon={solid('angle-right')} />}
                onClick={() => setId(next.id)}
              />
            )}
          </S.PhotoNav>
          <S.PhotoDetailInfo>
            <p>
              <span className="label">Email date:</span>
              <span>{dayjs(currentPhoto.emailDate).format('DD.MM.YYYY HH:mm:ss')}</span>
            </p>
            <p>
              <span className="label">Filename:</span>
              <span>{currentPhoto.fileName}</span>
            </p>
            <p>
              <span className="label">Filesize:</span>
              <span>{prettyBytes(currentPhoto.fileSize)}</span>
            </p>
            <p>
              <span className="label">Url:</span>
              <span>
                <a href={currentPhoto.filePath} target="_blank" rel="noreferrer">
                  {currentPhoto.filePath}
                </a>
              </span>
            </p>
            <p>
              <span className="label">Location:</span>
              <span>{currentPhoto.location}</span>
            </p>
          </S.PhotoDetailInfo>
        </Modal>
      )}
    </>
  );
};

export default PhotosTable;
